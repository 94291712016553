import api from './api'

class TemplateService {
    async postTemplate (data) {
        try {
            const response = await api.post('/api/v1/templates/', data)
            return response.data
        } catch (error) {
            return error.response
        }
    }

    async getTemplate () {
        try {
            const response = await api.get('/api/v1/templates/')
            return response.data
        } catch (error) {
            return error.response
        }
    }

    async getTemplateById (id) {
        try {
            const response = await api.get(`/api/v1/templates/${id}`)
            return response.data
        } catch (error) {
            return error.response
        }
    }

    async putTemplate (id, data) {
        try {
            const response = await api.put(`/api/v1/templates/${id}`, data)
            return response.data
        } catch (error) {
            return error.response
        }
    }

    async deleteTemplate (id) {
        try {
            const response = await api.delete(`/api/v1/templates/${id}`)
            return response.data
        } catch (error) {
            return error.response
        }
    }
}

export default new TemplateService()
