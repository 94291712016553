<template>
  <div class="px-1">
    <v-toolbar color="background" class="mb-1 mt-8" flat rounded="lg">
      <v-icon
        large
        color="primary"
        class="text-36 mr-2 d-none d-sm-block"
      >
        mdi-layers-outline
      </v-icon>
      <v-spacer />
      <div>
        <v-btn
          color="primary"
          depressed
          @click="sendEmail"
        >
          <v-icon class="mr-1">mdi-email-plus</v-icon>
          New Email
        </v-btn>
        <v-btn
          color="primary"
          depressed
          outlined
          class="ml-2"
          @click="showViewDialog = true"
        >
          <v-icon class="mr-1">mdi-layers-plus</v-icon>
          Add Template
        </v-btn>
      </div>
    </v-toolbar>
    <template-table
      :items="templatesData"
      v-on:reload-items="initializeData"
    />
    <add-template-dialog
      :showViewDialog.sync="showViewDialog"
      v-on:reload-items="initializeData"
    />
  </div>
</template>

<script>
  import TemplateTable from '@/components/table/TemplateTable.vue'
  import AddTemplateDialog from '@/components/dialog/templates/AddTemplateDialog.vue'
  import TemplateService from '@/services/template.service.js'

  export default {
    name: 'Templates',
    metaInfo: {
      title: 'Templates',
    },
    components: {
      TemplateTable,
      AddTemplateDialog,
    },
    data: () => ({
      templatesData: [],
      showViewDialog: false,
    }),
    created () {
      this.initializeData()
    },
    methods: {
      sendEmail () {
        this.$router.push('/send-email')
      },
      async initializeData () {
        const res = await TemplateService.getTemplate()
        if (res) {
          this.templatesData = res.results
          this.$store.dispatch('changeThemeLoadingState', false)
        }
      },
    },
  }
</script>
