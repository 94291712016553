<template>
  <div>
    <base-dialog
      :show="showEditDialog"
      form-title="Edit Template"
      size="45%"
      icon="mdi-pencil"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">NAME <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="editedItem.name"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter a template name"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">LANGUAGE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-select
              v-model="editedItem.language"
              placeholder="Select a language"
              :items="languages"
              :rules="[v => !!v || 'This field is required']"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">TITLE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-text-field
              v-model="editedItem.title"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter a title"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">MESSAGE <span class="h6 primary--text text--lighten-2">*</span></div>
            <v-textarea
              v-model="editedItem.message"
              :rules="[v => !!v || 'This field is required']"
              clearable
              outlined
              auto-grow
              placeholder="Enter your message here"
              min-height="180"
              no-resize
              dense
            />
          </v-col>

          <!--<v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0">
              <div class="text-left primary--text font-weight-bold">SUBTITLE <small class="font-italic font-weight-thin">(Optional)</small></div>
            </v-list-item-content>
            <v-text-field
              v-model="editedItem.subTitle"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0" style="width: 100%">
              <div class="text-left primary--text font-weight-bold">MESSAGE</div>
            </v-list-item-content>
            <editor
              v-model="editedItem.message"
              api-key=""
              :init="{
                statusbar: false,
                height: 280,
                content_css: colorTheme,
                plugins: 'table lists wordcount code',
                link_default_target: '_blank',
                object_resizing: false,
                toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline | forecolor backcolor | bullist numlist | alignleft aligncenter alignright | outdent indent',
              }"
            />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="my-0 py-0">
            <v-list-item-content class="text-left my-0" style="width: 100%">
              <div class="text-left primary--text font-weight-bold">SUBMESSAGE</div>
            </v-list-item-content>
            <editor
              v-model="editedItem.subMessage"
              api-key=""
              :init="{
                statusbar: false,
                height: 200,
                content_css: colorTheme,
                plugins: 'table lists wordcount code',
                link_default_target: '_blank',
                object_resizing: false,
                toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline | forecolor backcolor | bullist numlist | alignleft aligncenter alignright | outdent indent',
              }"
            />
          </v-col>-->
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import TemplateService from '@/services/template.service.js'
import snackbarPlugin from '@/plugins/snackbar'
import { languages } from '@/data/common'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    BaseDialog,
    editor: Editor
  },
  props: ['showEditDialog', 'editedItem'],
  data() {
    return {
      languages,
      valid: true,
      colorTheme: ''
    }
  },
  mounted() {
    if (this.$vuetify.theme.dark) {
      this.colorTheme = 'dark'
    }
  },
  methods: {
    close() {
      this.$emit('update:show-edit-dialog', false)
    },
    save() {
      if (this.$refs.form.validate()) {
        snackbarPlugin.showLoading('Updating ...')
        TemplateService.putTemplate(
          this.editedItem.id, this.editedItem
        ).then(() => {
          snackbarPlugin.showSuccess(`Template ${this.editedItem.name} updated!`)
          this.$emit('reload-items')
        })
        this.close()
      } else {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
