<template>
  <div>
    <base-dialog
      :show="showViewDialog"
      form-title="Add Template"
      size="45%"
      icon="mdi-layers-plus"
      v-on:close="close"
      v-on:save="save"
    >
      <template v-slot:form>
        <v-form
          ref="formAdd"
          v-model="valid"
          lazy-validation
        >
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              NAME <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="name"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter a template name"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              LANGUAGE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-select
              v-model="language"
              :items="languages"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Select a language"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              TITLE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-text-field
              v-model="title"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter a title"
              clearable
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              MESSAGE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-textarea
              v-model="message"
              :rules="[v => !!v || 'This field is required']"
              placeholder="Enter your message here"
              clearable
              outlined
              counter
              autocomplete="email"
              min-height="180"
              auto-grow
              no-resize
              dense
            />
          </v-col>

          <!--<v-col cols="12" class="my-0 py-0">
            <div class="primary--text font-weight-bold mb-1">SUBTITLE <small class="font-italic font-weight-thin">(Optional)</small></div>
            <v-text-field
              v-model="subTitle"
              clearable
              outlined
              dense
            />
          </v-col>-->
          <!--<v-col cols="12" class="mt-0 mb-6 py-0">
            <div class="primary--text font-weight-bold mb-1">MESSAGE</div>
            <editor
              v-model="message"
              api-key=""
              :init="{
                statusbar: false,
                height: 280,
                content_css: colorTheme,
                plugins: 'table lists wordcount code',
                link_default_target: '_blank',
                object_resizing: false,
                toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline | forecolor backcolor | bullist numlist | alignleft aligncenter alignright | outdent indent',
              }"
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div class="primary--text font-weight-bold mb-1">SUBMESSAGE</div>
            <editor
              v-model="subMessage"
              api-key=""
              :init="{
                statusbar: false,
                height: 200,
                content_css: colorTheme,
                plugins: 'table lists wordcount code',
                link_default_target: '_blank',
                object_resizing: false,
                toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline | forecolor backcolor | bullist numlist | alignleft aligncenter alignright | outdent indent',
              }"
            />
          </v-col>-->
        </v-form>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import TemplateService from '@/services/template.service.js'
import snackbarPlugin from '@/plugins/snackbar'
import { languages } from '@/data/common'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    BaseDialog,
    editor: Editor
  },
  props: {
    showViewDialog: {
      type: Boolean,
      required: true
    },
    items: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      languages,
      editedItem: {},
      valid: true,
      colorTheme: '',
      name: null,
      language: null,
      title: '',
      subTitle: '',
      message: '',
      subMessage: ''
    }
  },
  mounted() {
    if (this.$vuetify.theme.dark) {
      this.colorTheme = 'dark'
    }
  },
  methods: {
    close() {
      this.$emit('update:show-view-dialog', false)
      this.$refs.formAdd.reset()
      this.message = ''
      this.subMessage = ''
    },
    save() {
      if (this.$refs.formAdd.validate()) {
        snackbarPlugin.showLoading('Creating ...')
        TemplateService.postTemplate({
          name: this.name,
          language: this.language,
          title: this.title,
          subTitle: this.subTitle,
          message: this.message,
          subMessage: this.subMessage
        }).then(() => {
          this.$emit('reload-items')
          snackbarPlugin.showSuccess(`Template ${this.name} created!`)
          this.close()
        })
      }
      if (!this.$refs.formAdd.validate()) {
        snackbarPlugin.showError('Oops, complete the required fields!')
      }
    }
  }
}
</script>
