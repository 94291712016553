<template>
  <div>
    <edit-template-dialog
      :edited-item="editedItem"
      :show-edit-dialog.sync="showEditDialog"
      v-on:reload-items="$emit('reload-items')"
    />

    <delete-template-dialog
      :edited-item="editedItem"
      :show-delete-dialog.sync="showDeleteDialog"
      v-on:reload-items="$emit('reload-items')"
    />

    <v-card flat>
      <v-data-table
        :headers="headers"
        :items="localItems"
        :items-per-page="5"
      >
        <template v-slot:item.icon="{ item }">
          <v-avatar color="grey" class="my-5 d-none d-sm-flex">
            <v-icon medium class="my-2">
              mdi-layers-outline
            </v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.name="{ item }">
          <h6 v-if="item.isDeleted" class="error--text ma-0">{{ item.name }}</h6>
          <h6 v-else class="ma-0">{{ item.name }}</h6>
        </template>

        <template v-slot:item.language="{ item }">
          <v-chip v-if="item.language === 'fr'">Français</v-chip>
          <v-chip v-if="item.language === 'en'">English</v-chip>
        </template>

        <template v-slot:item.updatedAt="{ item }">
          <div>
            {{ new Date (item.updatedAt).toLocaleDateString('en-CA')
            }}
          </div>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <div>
            {{ new Date (item.createdAt).toLocaleDateString('en-CA')
            }}
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <span class="d-none d-lg-flex">
            <v-spacer />
            <!--<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  depressed
                  class=""
                  icon
                  large
                  :disabled="item.lastReportFile === null"
                  v-bind="attrs"
                  v-on="on"
                  @click="fullViewItem(item)"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>View Detail</span>
            </v-tooltip>-->

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="danger"
                  class=""
                  icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <v-spacer />
          </span>
          <v-menu
            bottom
            left
            class="d-md-flex d-lg-none"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                class="d-md-flex d-lg-none ml-14 "
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <!--<v-list-item
                link
                class="primary--text text--lighten-2"
                @click="fullViewItem(item)"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-magnify</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>View Detail</v-list-item-title>
                </v-list-item-content>
              </v-list-item>-->

              <v-list-item
                link
                class="primary--text text--lighten-2"
                @click="editItem(item)"
              >
                <v-icon class="mr-2" color="primary lighten-2">mdi-pencil</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                class="error--text"
                @click="deleteItem(item)"
              >
                <v-icon class="mr-2" color="error">mdi-delete-outline</v-icon>
                <v-list-item-content>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DeleteTemplateDialog from '../dialog/templates/DeleteTemplateDialog.vue'
import EditTemplateDialog from '@/components/dialog/templates/EditTemplateDialog'

export default {
  components: {
    DeleteTemplateDialog,
    EditTemplateDialog
  },
  props: {
    items: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: () => ({
    showDeleteDialog: false,
    showEditDialog: false,
    showViewDialog: false,
    editedItem: {},
    isAdmin: false,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'icon',
        width: '20px',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Template',
        value: 'name',
        class: 'text-left font-weight-medium subtitle-1'
      },
      {
        text: 'Language',
        value: 'language',
        align: 'center',
        sortable: false,
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Updated',
        value: 'updatedAt',
        align: 'center',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Created',
        value: 'createdAt',
        align: 'center',
        class: 'text-center font-weight-medium subtitle-1'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '180px',

        class: 'text-center font-weight-medium subtitle-1'
      }
    ]
  }),
  computed: {
    localItems() {
      return this.items
    }
  },
  mounted() {
  },
  methods: {
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showEditDialog = true
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showDeleteDialog = true
    },
    fullViewItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showViewDialog = true
    }
  }
}
</script>
